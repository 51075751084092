import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.25.0_next@15.1.3_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.4_hmrhvygnqhrktsvvy5oe7cey2u/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.25.0_next@15.1.3_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.4_hmrhvygnqhrktsvvy5oe7cey2u/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/react-bootstrap@2.10.7_@types+react@19.0.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/react-bootstrap/esm/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/react-bootstrap@2.10.7_@types+react@19.0.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/react-bootstrap/esm/Navbar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Menu/Menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Menu/MobileTabItem.tsx");
